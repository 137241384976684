import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Container, CssBaseline, Box, Typography, Button, Grid, Paper, TextField, Link } from '@mui/material';
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import { useInView } from 'react-intersection-observer';
import { playerOptionsLogo, playerOptionsText, screenshot1, screenshot2, screenshot3, screenshot4, screenshot5, screenshot6, screenshot7, screenshot8, screenshot9, golfBall, basketball, soccerBall, baseball } from './assets';
import './App.css';

const carouselSports = [
  "GOLF",
  "BASKETBALL",
  "SOCCER",
  "BASEBALL",
];

const carouselScreenshots = [screenshot1, screenshot2, screenshot3, screenshot4, screenshot5, screenshot6, screenshot7, screenshot8, screenshot9];

const CssTextField = styled(TextField)(({ theme }) => ({
  '& label.Mui-focused': {
    color: '#ADD8E6 !important',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#ADD8E6',
    },
  },
}));

const theme = createTheme({
  palette: {
    background: {
      default: '#000033',
    },
    text: {
      primary: '#FFF',
    },
  },
  typography: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: 16,
    body1: {
      color: '#FFF',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          margin: 0,
          color: '#FFF',
          backgroundColor: '#000033',
          fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      },
    },
  },
});

const App = () => {
  const { ref: aboutRef, inView: aboutInView } = useInView({ triggerOnce: false, delay: 150 });
  const { ref: carouselRef, inView: carouselInView } = useInView({ triggerOnce: false, delay: 150 });
  const { ref: featuresRef, inView: featuresInView } = useInView({ triggerOnce: false, delay: 150 });
  const { ref: testimonialsRef, inView: testimonialsInView } = useInView({ triggerOnce: false, delay: 150 });

  const sportSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0',
    autoplay: true,
    arrows: false,
    customPaging: i => (
      <div className={`custom-dot-container ${sportSettings.currentSlide === i ? 'selected' : ''}`}>
        <img
          src={
            i === 0 ? golfBall :
            i === 1 ? basketball :
            i === 2 ? soccerBall :
            baseball
          }
          alt="dot"
          className="custom-dot"
        />
      </div>
    ),
    beforeChange: (current, next) => sportSettings.currentSlide = next,
    currentSlide: 0 
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0',
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          centerPadding: '0',
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerPadding: '0',
        },
      },
    ],
  };

  const getAppStoreLink = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      return {
        link: "https://play.google.com/store/apps/details?id=com.profoliotrader.pft",
        text: "Download on Google Play"
      };
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return {
        link: "https://apps.apple.com/us/app/playeroptions/id6478463334",
        text: "Download on the App Store"
      };
    }

    return {
      link: "https://app-playeroptions.azurewebsites.net/",
      text: "Continue to the Web App"
    };
  };

  const appStore = getAppStoreLink();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="app-container">
        <Container maxWidth="lg">
          <Box component="header" className="header">
            <img src={playerOptionsLogo} alt="Player Options Logo" className="logo" />
            <img src={playerOptionsText} alt="Player Options Text" className="po-text" />
            <div className="banner">
              <Slider {...sportSettings}>
                {carouselSports.map((sport, index) => (
                  <div key={index} className="carousel-slide">
                    {sport}
                  </div>
                ))}
              </Slider>
            </div>
          </Box>

          <Box component="section" id="about-app" ref={aboutRef} className={`section ${aboutInView ? 'in-view' : ''}`}>
            <Paper className="paper">
              <Typography variant="h4" component="div" gutterBottom>Sign Up Now!</Typography>
              <Box component="form" action="https://app-playeroptions.azurewebsites.net/signup.php" method="post">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <CssTextField fullWidth label="Email" name="email" required variant="outlined" InputProps={{ className: 'input' }} InputLabelProps={{ className: 'label' }} />
                  </Grid>
                  <Grid item xs={6}>
                    <CssTextField fullWidth label="First Name" name="first_name" required variant="outlined" InputProps={{ className: 'input' }} InputLabelProps={{ className: 'label' }} />
                  </Grid>
                  <Grid item xs={6}>
                    <CssTextField fullWidth label="Last Name" name="last_name" required variant="outlined" InputProps={{ className: 'input' }} InputLabelProps={{ className: 'label' }} />
                  </Grid>
                  <Grid item xs={12}>
                    <CssTextField fullWidth label="Password" name="psw" type="password" required variant="outlined" InputProps={{ className: 'input' }} InputLabelProps={{ className: 'label' }} />
                  </Grid>
                  <Grid item xs={12}>
                    <CssTextField fullWidth label="Confirm Password" name="psw-repeat" type="password" required variant="outlined" InputProps={{ className: 'input' }} InputLabelProps={{ className: 'label' }} />
                  </Grid>
                  <Grid item xs={12}>
                    <Button fullWidth variant="contained" color="primary" type="submit">Sign Up</Button>
                  </Grid>
                </Grid>
              </Box>
              <Typography variant="body1" sx={{ mt: 2 }}>Join Player Options and start trading athletes across multiple sports. Sign up now to experience the thrill of sports performance trading!</Typography>
            </Paper>
          </Box>

          <Box component="section" id="carousel" ref={carouselRef} className={`section ${carouselInView ? 'in-view' : ''}`}>
            <Paper className="paper">
              <Typography variant="h4" component="div" gutterBottom>Explore Our App</Typography>
              <Slider {...settings}>
                {carouselScreenshots.map((src, index) => (
                  <div key={index} className="carousel-slide">
                    <img src={src} alt={`Slide ${index + 1}`} style={{ width: '100%', borderRadius: '8px' }} />
                  </div>
                ))}
              </Slider>
            </Paper>
          </Box>

          <Box component="section" id="features" ref={featuresRef} className={`section ${featuresInView ? 'in-view' : ''}`}>
            <Paper className="paper">
              <Typography variant="h4" component="div" gutterBottom>Why Player Options?</Typography>
              <ul className="features-list">
                <li><Typography variant="body1">Trade athletes from a variety of sports, including basketball, golf, soccer, and more.</Typography></li>
                <li><Typography variant="body1">Dynamic player values and contests spanning different periods.</Typography></li>
                <li><Typography variant="body1">Advanced analytics for informed trading decisions.</Typography></li>
                <li><Typography variant="body1">Community platform for engaging with other traders.</Typography></li>
                <li><Typography variant="body1">Resources for traders of all skill levels.</Typography></li>
                <li><Typography variant="body1">A secure and responsible trading environment.</Typography></li>
              </ul>
              <Typography variant="body1" sx={{ mt: 2 }}>Discover the thrill of trading athletes across multiple sports in one app. Player Options brings you an unparalleled experience in sports trading.</Typography>
            </Paper>
          </Box>

          <Box component="section" id="testimonials" ref={testimonialsRef} className={`section ${testimonialsInView ? 'in-view' : ''}`}>
            <Paper className="paper">
              <Typography variant="h4" component="div" gutterBottom>User Testimonials</Typography>
              <Box>
                <Typography variant="body1" sx={{ mb: 2 }}><b>Revolutionizing Sports Enjoyment</b> - "Player Options has revolutionized my sports watching experience. Trading across basketball, golf, and more makes every game thrilling. Can't get enough of it!"</Typography>
                <Typography variant="body1" sx={{ mb: 2 }}><b>Engaging and Innovative</b> - "The real-time trading feature has added a new dimension to sports for me. Player Options is engaging, fun, and keeps sports enthusiasts like me constantly on our toes!"</Typography>
                <Typography variant="body1" sx={{ mb: 2 }}><b>Unmatched Sports Trading Experience</b> - "From basketball to golf, Player Options offers an unmatched trading experience. It's fun, interactive, and introduces a competitive edge to sports watching."</Typography>
                <Typography variant="body1" sx={{ mb: 2 }}><b>Highly Recommended</b> - "For anyone looking for an exciting way to engage with sports, Player Options is a must-try. It has made watching sports not just a pastime but an interactive experience."</Typography>
              </Box>
            </Paper>
          </Box>
        </Container>
        <Box component="footer" className="footer">
          <Typography variant="body1">Need help?<br></br>Email us at support@player-options.com</Typography>
          <Box sx={{ mt: 2 }}>
            <Link href="https://www.instagram.com/playeroptions/" color="inherit" target="_blank" rel="noopener">Instagram</Link>&nbsp;|&nbsp;
            <Link href="https://twitter.com/playeroptions" color="inherit" target="_blank" rel="noopener">Twitter</Link>&nbsp;|&nbsp;
            <Link href="https://www.linkedin.com/company/playeroptions" color="inherit" target="_blank" rel="noopener">LinkedIn</Link>&nbsp;|&nbsp;
            <Link href="https://facebook.com/playeroptions" color="inherit" target="_blank" rel="noopener">Facebook</Link>
          </Box>
        </Box>
        <Button
          variant="contained"
          color="primary"
          href={appStore.link}
          sx={{
            position: 'fixed',
            bottom: '20px',
            width: '80%',
            maxWidth: '400px',
            left: '50%',
            transform: 'translateX(-50%)',
            backgroundColor: '#0074f6',
            color: '#fff',
            padding: '8px 12px',
            border: '1px solid #1c2674',
            borderRadius: '20px',
            fontSize: '1rem',
            fontWeight: 'bold',
            textAlign: 'center',
            textDecoration: 'none',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)',
            transition: 'background-color 0.3s ease',
            zIndex: 1000,
            '&:hover': {
              backgroundColor: '#fff',
              color: '#0074f6'
            }
          }}
        >
          {appStore.text}
        </Button>
      </div>
    </ThemeProvider>
  );
}

export default App;
